<template>
  <BaseModalIntro
    v-if="showValue"
    :introTitle="'Gifts'"
    :showValue="showValue"
    :show="show"
    @close="$emit('close')"
    @doNotShowAgain="doNotShowAgain"
  >
    <template v-slot:contents>
      <h5>Overview</h5>

      <p>
        Before an estate is divided up, people sometimes leave specific gifts to
        people or charities. A specific gift is a particular item or sum of
        money that you wish to give someone in your Will, for example, a car, a
        property or £1,000.
      </p>

      <h5>Choices</h5>

      <p>
        For each gift you make there are three choices that need to be
        considered. Firstly you must decide what is to be gifted. Gifts of money
        or personal items are commonplace, however almost anything can be gifted
        including vehicles, properties and even businesses. It is important that
        the gift is described precisely in your Will, so that the executors can
        understand exactly what you intended. Secondly the gift needs a
        recipient, usually this will be a person or a charity. Finally you need
        to decide when the gift should be given, should it be pass 'directly'
        (even if your partner is still alive), or instead only on '2nd death'
        (when both yourself and your partner have passed away).
      </p>

      <h5>Examples</h5>

      <p>
        As an example Jill Bloggs is married to Jo Bloggs, she is leaving two
        gifts in her Will, her remaining estate is to be split between her
        children equally. She is leaving her diamond ring to her daughter
        directly. In addition she is leaving £10,000 to the RSPCA on 2nd death.
        The gift of a ring will happen when Jill passes away, whether she goes
        before or after Jo. However the charitable gift will pass only if her
        husband Jo has passed away before her.
      </p>
    </template>
  </BaseModalIntro>
</template>

<script>
import BaseModalIntro from '@/common/ui/BaseModalIntro'

export default {
  name: 'GiftsIntroModal',
  props: ['show'],

  components: {
    BaseModalIntro
  },
  computed: {
    showValue() {
      return this.$store.getters.showIntroModals.gifts
    }
  },
  methods: {
    doNotShowAgain() {
      this.$store.commit('showIntroModals', { gifts: false })
    }
  }
}
</script>
