<template>
  <NavQuestion
    :heading="heading"
    :number="'1'"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputSwitch :checked="details.gifts" @toggle="save"></InputSwitch>
      <IntroModal
        v-show="show.intro"
        :show="show.intro"
        @close="show.intro = false"
      ></IntroModal>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="'/home'"
        v-bind:center="false"
        v-bind:loading="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputSwitch from '@/common/ui/InputSwitch'
import IntroModal from '../IntroModal'

export default {
  name: 'GiftsLeaveOption',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputSwitch,
    IntroModal
  },
  computed: {
    isValidated() {
      return true
    },
    partner() {
      return this.$store.getters.partner
    },
    heading() {
      return 'Would you like to leave any gifts in your Will?'
    },
    subHeading() {
      return (
        'Gifts can be made before everything else is distributed (see the distribution section). ' +
        'Gifts of money are not uncommon, similarly individuals may be gifted particular assets or personal items.'
      )
    },
    forwardTo() {
      if (!this.partner && this.details.gifts) return '/gifts/direct'
      else if (this.partner && this.details.gifts) return '/gifts/direct_option'
      return '/gifts/check_and_confirm'
    },
    details() {
      return this.$store.getters.details
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.partner) {
      this.$store.commit('details', {
        gifts_before: true
      })
    }
    if (this.details && this.details.gifts === null) this.saveFalse()
    next()
  },
  data() {
    return {
      loading: false,
      show: {
        intro: true
      }
    }
  },
  methods: {
    save() {
      if (this.details) {
        this.$store.commit('details', {
          gifts: !this.details.gifts
        })
      }
    },
    saveFalse() {
      if (this.details) {
        this.$store.commit('details', {
          gifts: false
        })
      }
    }
  }
}
</script>
